

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";
import {ManagerOfGroupCreate} from "@/models/managerOfGroupCreate";
import {ManagerOfGroup} from "@/models/managerOfGroup";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const group = ref({} as GroupCreate);
    const listGroup = ref([] as Group[]);
    const member = ref({} as MemberInGroupCreate);
    const listMember = ref([] as MemberInGroup[]);
    const manager = ref({} as ManagerOfGroupCreate);
    const listManager = ref([] as ManagerOfGroup[]);
    const listCustomer = ref([] as ListCustomer[]);

    // if(!(!!document.cookie)) {
    //   router.push({
    //     name: 'login'
    //   });
    // }

    const getListCustomer = () => {
      ZaloRepository.getListCustomerWithOutEmployee()
          .then((response) => {
            listCustomer.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi không tìm thấy danh sách tài khoản',
              life: 2000
            });
          });
    }
    getListCustomer();
const getList = () => {
  ZaloRepository.listGroup()
      .then((response) => {
        listGroup.value = response.data;
      })
      .catch(err => {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:'Lỗi khi tải danh sách khách hàng',
          life: 2000
        });
      });
}
    getList();
    const createGroup = () => {
      ZaloRepository.createGroup(group.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Tạo nhóm',
              detail: 'Tạo nhóm thành công',
              life: 2000
            });
            getList();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createMember = () => {
      listCustomer.value.forEach(x => {
        if(x.id == member.value.idZalo) member.value.zaloName = x.name;
      })
      listGroup.value.forEach(x => {
        if(x.id == member.value.idGroup) member.value.groupName = x.groupName;
      })
      ZaloRepository.createMemberInGroup(member.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thêm thành viên',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            getListMemberInGroup(member.value.idGroup);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deleteMember = (id: number) => {
      ZaloRepository.deleteMemberInGroup(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xoá thành viên',
              detail: 'Xóa thành viên khỏi nhóm thành công',
              life: 2000
            });
            listMember.value = listMember.value.filter(x => x.id != id);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListMemberInGroup = (id: number) => {
      listMember.value = [];
      ZaloRepository.listMemberInGroup(id)
          .then((response) => {
            listMember.value = response.data;
            listMember.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.idZalo) x.imageLink = y.imageUrl;
                console.log("########## image link: " + x.imageLink);
              })
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    const createManager = () => {
      listCustomer.value.forEach(x => {
        if(x.id == manager.value.idZalo) manager.value.zaloName = x.name;
      })
      listGroup.value.forEach(x => {
        if(x.id == manager.value.idGroup) manager.value.groupName = x.groupName;
      })
      ZaloRepository.createManagerOfGroup(manager.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thêm quản lý',
              detail: 'Thêm quản lý vào nhóm thành công',
              life: 2000
            });
            getListManagerOfGroup(manager.value.idGroup);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const deleteManager = (id: number) => {
      ZaloRepository.deleteManagerOfGroup(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xoá quản lý',
              detail: 'Xóa quản lý khỏi nhóm thành công',
              life: 2000
            });
            listManager.value = listManager.value.filter(x => x.id != id);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const getListManagerOfGroup = (id: number) => {
      listManager.value = [];
      ZaloRepository.listManagerOfGroup(id)
          .then((response) => {
            listManager.value = response.data;
            listManager.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.idZalo) x.imageLink = y.imageUrl;
                console.log("########## image link: " + x.imageLink);
              })
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    return {
      group,
      createGroup,
      member,
      listMember,
      manager,
      listManager,
      listGroup,
      createManager,
      createMember,
      deleteManager,
      deleteMember,
      getListManagerOfGroup,
      getListMemberInGroup,
      listCustomer
    }
  }
}

