

import moment from 'moment';
import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import zaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";
import {CarObject} from "@/models/carObject";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listCarObject = ref([] as CarObject[]);
    const carObject = ref({} as CarObject);

    const loadListCar = () => {
      zaloRepository.getListCar()
          .then((response) => {
            listCarObject.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách xe',
              life: 2000
            });
          });
    }

    const deleteCar = (id) => {
      zaloRepository.deleteCar(id)
          .then((response) => {
            toast.add({
              severity: 'warn',
              summary: 'Thành công',
              detail:'Xe đã được xoá thành công khỏi hệ thống',
              life: 2000
            });
            loadListCar();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi xoá xe',
              life: 2000
            });
          });
    }

    const createCar = () => {
      zaloRepository.createCar(carObject.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm thành công xe vào danh sách',
              life: 2000
            });
            loadListCar();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi thêm xe',
              life: 2000
            });
          });
    }

    loadListCar();
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      console.log("@@@@@@@@@################ formatDateTime date: " + date.value);
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm:ss DD/MM/YYYY');
    };

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      deleteCar,
      createCar,
      listCarObject,
      carObject,
      formatDateTime
    }
  }
}

