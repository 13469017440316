

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import {RegisterService} from "@/models/registerService";
  import ZaloRepository from "@/services/ZaloRepository";
  import {ListService} from "@/models/listService";
  import {useRouter} from "vue-router";
  import {NghiPhepDetail} from "@/models/nghiPhepDetail";
  import zaloRepository from "@/services/ZaloRepository";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const date = ref();
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const listDayOff = ref([] as NghiPhepDetail[]);

      const selectCalendar = () => {
        if((date.value[0] != null)&&(date.value[1] != null))
        {
          listDayOff.value = [];
          zaloRepository.getListDayOff(date.value[0]/1000, date.value[1]/1000)
              .then((response) => {
                listDayOff.value = response.data;
                console.log("@@@@@@@@@################ list: " + JSON.stringify(listDayOff.value));
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      };

      //selectCalendar();

      const clearCalendar = () => {
        listDayOff.value = [];
      }

      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format(' DD/MM/YYYY');
      };


      const validDate = (registerService: RegisterService) => {
        return registerService.timeConfirm;
      }

      const clearDate = () => {
        console.log("@@@@@@@@@################ clearDate");
        date.value = new Date();
      }

      const status = (id, xn) => {
        if(xn) return "Cho phép nghỉ";
        else {
          if(id > 0) return "Không cho phép nghỉ";
          else return "Chưa xác nhận";
        }
      }

      return {
        formatDateTime,
        listDayOff,
        date,
        selectCalendar,
        clearCalendar,
        service,
        validDate,
        kind,
        clearDate,
        status
      }
    }

  }
