

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import {ThongTin} from "@/models/thongTin";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import {Ongtiem} from "@/models/ongtiem";
  import {Employee} from "@/models/employee";
  import ZaloRepository from "@/services/ZaloRepository";
  import {Team} from "@/models/team.models";
  import TeamRepository from "@/services/TeamRepository";
  import {ListCustomer} from "@/models/listCustomer";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const listEmployee = ref([] as Employee[]);
      const teams = ref([] as Team[]);
      const listCustomer = ref([] as ListCustomer[]);

      const getList = () => {
        ZaloRepository.getListCustomer()
            .then((response) => {
              listCustomer.value = response.data;
              loadData();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:'Lỗi khi tải danh sách khách hàng',
                life: 2000
              });
            });
      }
      getList();

      const loadData = () => {
        TeamRepository.getTeams()
            .then((response) => {
              teams.value = response.data;
              ZaloRepository.getListEmployee()
                  .then((response) => {
                    listEmployee.value = response.data;
                    listEmployee.value.forEach(x => {teams.value.forEach(y => {if(y.code == x.teamCode) x.teamCode = y.name})});
                    listEmployee.value.forEach(x => {
                      listCustomer.value.filter(y => {
                        if(y.id == x.zaloId) x.imageLink = y.imageUrl;
                        console.log("########## image link: " + x.imageLink);
                      })
                    });
                  })
                  .catch(err => {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail:err.response.data,
                      life: 2500
                    })});
            })
            .catch();
      };

      const deleteEmployee = (id: number) => {
        ZaloRepository.deleteEmployee(id)
            .then((response) => {
              toast.add({
                severity: 'success',
                summary: 'Đăng ký',
                detail: 'Xoá nhân viên thành công',
                life: 2000
              });
              loadData();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };

      const formatDateTime = (date) => {
        return moment(String(date)).format('DD/MM/YYYY HH:mm');
      };

      const formatDateTime2 = (date) => {
        return moment(String(date)).format('DD/MM/YYYY');
      };

      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const openLink = (id: any) => {
        let routeData = router.resolve({name: 'updateemployee', params: {id: id}});
        window.open(routeData.href, '_blank');
      };

      return {
        formatDateTime,
        filters,
        clearFilter,
        formatDateTime2,
        listEmployee,
        openLink,
        deleteEmployee,
      }
    }

  }
