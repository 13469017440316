

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {EmployeeCheckIn} from "@/models/employeeCheckIn";
import {Department} from "@/models/department";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const customer = ref({} as ListCustomer);
    const teamId = ref(0);
    const listCustomer = ref([] as ListCustomer[]);
    const teams = ref([] as Team[]);
    const employee = ref({} as EmployeeCheckIn);

    const employeeCreate = ref({} as EmployeeCreate);

    const valid = computed(()=> employee.value.fullName && employee.value.phoneNumber && idDepartment.value > 0);

    const listDepartment =  ref([] as Department[]);
    const idDepartment = ref(0);
    const getListDepartments = () => {
      zaloRepository.getListDepartments()
          .then((response) => {
            listDepartment.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })})
    };
    getListDepartments();

    const doRegister = () => {
      listDepartment.value.forEach(x => {
        if(x.id == idDepartment.value){
          employee.value.departmentsName = x.name;
          employee.value.idDepartments = x.id;
        }
      })
        zaloRepository.createEmployeeInHospital(employee.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }

    return {
      register,
      doRegister,
      valid,
      teams,
      error,
      listCustomer,
      customer,
      teamId,
      employee,
      idDepartment,
      listDepartment
    }
  }
}

