

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import zaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";
import {ManagerBookingCreate} from "@/models/managerBookingCreate";
import {ManagerBooking} from "@/models/managerBooking";
import {DoctorExamineCreate} from "@/models/doctorExamineCreate";
import {EmployeeInHospital} from "@/models/employeeInHospital";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listCustomer = ref([] as ListCustomer[]);
    const managerBooking = ref({} as ManagerBookingCreate);
    const listManagerBooking = ref([] as ManagerBooking[]);
    const doctorExamine = ref({} as DoctorExamineCreate);
    const listDoctorExamine = ref([] as DoctorExamineCreate[]);
    const listEmployeeInHospital = ref([] as EmployeeInHospital[]);

    const loadData = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách khách hàng',
              life: 2000
            });
          });

      zaloRepository.getListDoctorExamine()
          .then((response) => {
            listDoctorExamine.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách bác sĩ',
              life: 2000
            });
          });

      zaloRepository.getListEmployeeInHospital()
          .then((response) => {
            listEmployeeInHospital.value = response.data;
            listEmployeeInHospital.value.forEach(x => x.fullNameWithPhone = x.fullName + " - " + x.phoneNumber);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách bác sĩ',
              life: 2000
            });
          });
    }

    loadData();

    const loadListManager = () => {
      zaloRepository.getListManagerOfBooking()
          .then((response) => {
            listManagerBooking.value = response.data;
            listManagerBooking.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.zaloId) x.imageLink = y.imageUrl;
              })
            });
            console.log("@@################### loadListManager listManagerBooking : " + JSON.stringify(listManagerBooking.value))
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:'Lỗi khi tải danh sách nhận góp ý',
            //   life: 2000
            // });
          });
    }



    const addMember = () => {
      listCustomer.value.forEach(x => {
        if(x.id == managerBooking.value.zaloId){
          managerBooking.value.fullName = x.name;
        }
      })
      zaloRepository.addManagerOfBooking(managerBooking.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const addDoctor = () => {
      console.log("#########$$$$$$$$$$$$$$$$$ doctorExamine.value.zaloId: " + doctorExamine.value.zaloId);
      console.log("#########$$$$$$$$$$$$$$$$$ listEmployeeInHospital: " + JSON.stringify(listEmployeeInHospital.value));
      listEmployeeInHospital.value.forEach(x => {
        if(x.zaloId == doctorExamine.value.zaloId){
          console.log("#########$$$$$$$$$$$$$$$$$ x: " + JSON.stringify(x));
          doctorExamine.value.doctorName = x.fullName;
          doctorExamine.value.phoneNumber = x.phoneNumber;
        }
      })
      zaloRepository.createDoctorExamine(doctorExamine.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm bác sĩ thành công',
              life: 2000
            });
            zaloRepository.getListDoctorExamine()
                .then((response) => {
                  listDoctorExamine.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:'Lỗi khi tải danh sách bác sĩ',
                    life: 2000
                  });
                });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteDoctor = (id: number) => {
      zaloRepository.deleteDoctorExamine(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa bác sĩ thành công',
              life: 2000
            });
            zaloRepository.getListDoctorExamine()
                .then((response) => {
                  listDoctorExamine.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:'Lỗi khi tải danh sách bác sĩ',
                    life: 2000
                  });
                });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteManager = (id: number) => {
      zaloRepository.deleteManagerOfBooking(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      error,
      managerBooking,
      listCustomer,
      addMember,
      deleteManager,
      listManagerBooking,
      filters,
      clearFilter,
      initFilters,
      listEmployeeInHospital,
      doctorExamine,
      listDoctorExamine,
      addDoctor,
      deleteDoctor
    }
  }
}

